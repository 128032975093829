.trade-history {
    width: 100%; /* Ensures the table fits its container */
  }
  
  .trade-history table {
    width: 100%; /* The table spans the entire parent container */
    border-collapse: collapse; /* Removes gaps between table cells */
  }
  
  .trade-history th,
  .trade-history td {
    padding: 10px;
    text-align: center; /* Center-aligns text for consistency */
    border: 1px solid #333; /* Keeps borders consistent with the design */
    color: #d6d6d6;
    overflow: hidden; /* Prevents overflowing content */
    white-space: nowrap; /* Avoids text wrapping */
    text-overflow: ellipsis; /* Adds ellipsis for truncated content */
  }
  
  .trade-history th {
    background: #243b55;
    font-weight: bold;
  }
  
  .trade-history tr:nth-child(even) {
    background: #1b2735; /* Alternating row colors for readability */
  }
  
  .trade-history tr:hover {
    background: #1e2a3a; /* Highlight the row on hover */
  }
  
  .trade-history td {
    max-width: 100px; /* Sets a maximum width for content in table cells */
  }
  
  /* Positive and negative P&L styling */
  .trade-history td:last-child {
    color: #fff; /* Default text color for P&L */
  }
  
  .trade-history td:last-child[style*="color: green"] {
    color: green; /* For positive P&L */
  }
  
  .trade-history td:last-child[style*="color: red"] {
    color: red; /* For negative P&L */
  }
  