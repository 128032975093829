/* App.css */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #10141c, #1a1f2d);
  color: #d6d6d6;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: linear-gradient(90deg, #1e2a3a, #243b55);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  flex-wrap: wrap;
  position: relative;
}

.logo {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #68d2e0, #ff4d4d);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #10141c;
  font-weight: bold;
  text-transform: uppercase;
}

.logoimg {
  width: 60px;
  height: auto;
  border-radius: 1000px;
}

.nav {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  transition: all 0.3s ease;
}

.nav-item {
  background: linear-gradient(90deg, #1b2735, #23344a);
  color: #d6d6d6;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
}

.nav-item:hover {
  background: linear-gradient(90deg, #68d2e0, #ff4d4d);
  color: #10141c;
  box-shadow: 0 4px 10px rgba(255, 77, 77, 0.4), 0 -4px 10px rgba(104, 210, 224, 0.4);
}

.nav-item:active {
  background: linear-gradient(90deg, #ff4d4d, #68d2e0);
  transform: scale(0.98);
}

.nav-item.active {
  background: linear-gradient(90deg, #68d2e0, #58c0d0);
  color: #10141c;
  font-weight: bold;
}

.wallet-button {
  font-size: 1rem;
  color: #ffffff;
  padding: 10px 20px;
  background: linear-gradient(90deg, #1b2735, #23344a);
  border: 1px solid #68d2e0;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
}

.wallet-button:hover {
  background: linear-gradient(90deg, #68d2e0, #ff4d4d);
  color: #10141c;
  box-shadow: 0 4px 10px rgba(255, 77, 77, 0.4), 0 -4px 10px rgba(104, 210, 224, 0.4);
}

.nav-item i {
  margin-right: 8px;
  font-size: 1rem;
  vertical-align: middle;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #d6d6d6;
  cursor: pointer;
  z-index: 10;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Mobile and tablet-specific styles */
@media (max-width: 1400px) {
  .nav {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .nav-open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    width: 80%;
    background: linear-gradient(135deg, #1e2a3a, #243b55);
    z-index: 9;
    padding: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .nav-open .nav-item {
    text-align: left;
    padding: 10px;
    margin: 5px 0;
  }
}


.tradingview-chart-container {
  padding: 20px;
  background: linear-gradient(135deg, #68d2e0, #ff4d4d); /* Teal and red gradient */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  
  max-width: 1200px;
  height: 100%;
  width:60%;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-shadow: 0 0 10px rgba(104, 210, 224, 0.4), 0 0 10px rgba(255, 77, 77, 0.4);
}

@media (max-width: 768px) {
  .tradingview-chart-container {
    width: 80%; /* Slightly reduce width on smaller screens */
    height: 500px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 480px) {
  .tradingview-chart-container {
    width: 80%; /* Use full width on very small screens */
    height: 400px; /* Adjust height for mobile devices */
  }
}
.nav-item.active {
  background: linear-gradient(90deg, #68d2e0, #58c0d0);
  color: #10141c;
  font-weight: bold;
}
