.open-positions table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .open-positions th,
  .open-positions td {
    padding: 10px;
    text-align: center;
    border: 1px solid #333;
    color: #d6d6d6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .open-positions th {
    background: #243b55;
    font-weight: bold;
  }
  
  .open-positions tr:nth-child(even) {
    background: #1b2735;
  }
  
  .open-positions tr:hover {
    background: #1e2a3a;
  }
  
  .mobile-cards {
    display: none; /* Default to hidden on desktop */
  }
  
  .position-card {
    background: #1b2735;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .position-card div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333;
    padding: 5px 0;
  }
  
  .position-card div:last-child {
    border-bottom: none;
  }
  
  .position-card label {
    font-weight: bold;
    color: #68d2e0;
  }
  
  .position-card span {
    color: #d6d6d6;
  }
  
  .position-card button {
    margin-top: 10px;
    padding: 5px 15px;
    background: linear-gradient(90deg, #ff4d4d, #ff6b6b);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .show-more-btn {
    padding: 10px 20px;
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
  
  .show-more-btn:hover {
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
  }
  
  @media (max-width: 768px) {
    .open-positions table {
      display: none;
    }
  
    .mobile-cards {
      display: block;
    }
  }
  