.risk-tools {
    text-align: center;
    padding: 50px;
    background: linear-gradient(135deg, #10141c, #1a1f2d);
    color: #d6d6d6;
  }
  
  .risk-tools h1 {
    font-size: 2.5rem;
    color: #68d2e0;
    margin-bottom: 20px;
  }
  
  .risk-tools p {
    font-size: 1.5rem;
    color: #ff4d4d;
    font-weight: bold;
  }
  