.position-management {
  padding: 20px;
  background: linear-gradient(135deg, #10141c, #1a1f2d);
  color: #d6d6d6;
}

.position-management h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #68d2e0;
  margin-bottom: 20px;
}

/* Grid layout for content */
.position-content {
  display: grid;
  gap: 20px;
}

.row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Columns for side-by-side layout */
.column {
  flex: 1;
  min-width: 400px;
  background: linear-gradient(135deg, #1b2735, #243b55);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

/* Ensure responsive stacking for smaller screens */
@media (max-width: 1368px) {
  .row {
    flex-direction: column;
  }

  .column {
    width: auto;
  }
}
