/* General Container */
.market-analysis {
    padding: 20px;
    background: linear-gradient(135deg, #10141c, #1a1f2d);
    color: #d6d6d6;
  }
  
  .market-analysis h1 {
    text-align: center;
    font-size: 2rem; /* Smaller font size for mobile */
    color: #68d2e0;
    margin-bottom: 20px;
  }
  
  /* Market Overview Section */
  .market-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Dynamic grid */
    gap: 10px;
    background: linear-gradient(135deg, #1b2735, #23344a);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .market-overview div {
    text-align: center;
    padding: 10px;
    background: linear-gradient(135deg, #243b55, #2c3e50);
    border-radius: 8px;
  }
  
  .market-overview label {
    font-weight: bold;
    color: #68d2e0;
  }
  
  .market-overview span {
    display: block;
    color: #fff;
    font-size: 1rem; /* Reduced font size for mobile */
  }
  
  /* Trading View Chart Section */
  .chart-section {
    background: linear-gradient(135deg, #1b2735, #243b55);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .tradingview-chart {
    padding: 10px;
    background: linear-gradient(135deg, #1b2735, #243b55);
    border-radius: 10px;
    margin: 0 auto;
    max-width: 100%; /* Use the full width of the parent container */
    height: 400px; /* Adjust height for mobile */
    overflow: hidden;
  }
  
  /* Trending Pairs Section */
  .trending-pairs {
    margin-top: 20px;
    background: linear-gradient(135deg, #1b2735, #243b55);
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .trending-pairs ul {
    list-style: none;
    padding: 0;
  }
  
  .trending-pairs li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #333;
    color: #d6d6d6;
  }
  
  .trending-pairs li:hover {
    background: #243b55;
    cursor: pointer;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .market-overview {
     display: flex;
     flex-direction: column;
    }
  
    .tradingview-chart {
      height: 300px; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .market-overview {
      grid-template-columns: 1fr; /* Stack cards in one column on small screens */
    }
  
    .tradingview-chart {
      height: 250px; /* Further reduce height for mobile */
    }
  
    .market-analysis h1 {
      font-size: 1.5rem; /* Smaller heading for mobile */
    }
  }
  