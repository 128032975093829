.tradingview-chart {
    padding: 20px;
    background: linear-gradient(135deg, #1b2735, #243b55); /* Subtle gradient */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    margin: 20px auto;
    width: 100%;
    max-width: 1200px; /* Limit maximum width */
    height: 400px; /* Use percentage to inherit parent's height */
    overflow: hidden; /* Prevent overflow */
    display: flex; /* Flexbox to handle centering */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    box-sizing: border-box; /* Include padding in size calculations */
  }
  
  .tradingview-chart iframe {
    width: 100%;
    height: 100%;
    border: none; /* Remove the iframe border */
  }
  
  @media (max-width: 768px) {
    .tradingview-chart {
      max-width: 95%; /* Adjust for smaller screens */
      height: 400px; /* Reduce height */
    }
  }
  
  @media (max-width: 480px) {
    .tradingview-chart {
      height: 300px; /* Further reduce height for mobile */
    }
  }
  