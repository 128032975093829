.risk-management {
   
    padding: 20px;
    border-radius: 10px;
 
    color: #d6d6d6;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden; /* Prevent content overflow */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .risk-management h2 {
    font-size: 1.8rem;
    text-align: center;
    color: #68d2e0;
    margin-bottom: 20px;
    word-wrap: break-word; /* Ensure long text breaks gracefully */
  }
  
  .slider-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden; /* Prevent horizontal overflow */
  }
  
  .slider-section label {
    font-weight: bold;
    color: #a5abb7;
    word-wrap: break-word; /* Ensure labels don't overflow */
  }
  
  .slider-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; /* Ensure content wraps on smaller screens */
    max-width: 100%; /* Prevent sliders and inputs from exceeding container width */
  }
  
  .slider-container input[type="range"] {
    flex: 2;
    -webkit-appearance: none;
    height: 5px;
    background: #1e2a3a;
    border-radius: 5px;
    outline: none;
    transition: background 0.3s;
    max-width: 100%; /* Prevent overflow */
  }
  
  .slider-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: linear-gradient(135deg, #68d2e0, #ff4d4d);
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .slider-container input[type="number"] {
    width: 70px;
    padding: 5px;
    border: 1px solid #68d2e0;
    border-radius: 5px;
    background: #1e2a3a;
    color: #d6d6d6;
    text-align: center;
    overflow: hidden; /* Prevent text overflow */
  }
  
  .slider-container input[type="number"]:focus {
    outline: none;
    border-color: #ff4d4d;
  }
  
  .save-btn {
    width: 100%;
    padding: 10px;
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    border: none;
    border-radius: 5px;
    color: #10141c;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
  }
  
  .save-btn:hover {
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
  }
  
  /* Ensure responsiveness for smaller screens */
  @media (max-width: 768px) {
    .slider-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .slider-container input[type="range"] {
      width: 100%;
    }
  
    .slider-container input[type="number"] {
      width: 100%;
      text-align: left;
    }
  
    .save-btn {
      font-size: 0.9rem;
    }
  }
  