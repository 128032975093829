.new-order {
    padding: 20px;
    border-radius: 10px;
    color: #d6d6d6;
    max-width: 100%;
  }
  
  .new-order h2 {
    text-align: center;
    color: #68d2e0;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group select,
  .slider-value {
    padding: 10px;
    background: #1e2a3a;
    border: 1px solid #68d2e0;
    border-radius: 5px;
    color: #d6d6d6;
    width: 100%;
    box-sizing: border-box;
  }
  
  .slider-group .slider-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .slider-group input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    height: 5px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }
  
  .slider-group input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider-group input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider-group .slider-value {
    width: 60px;
    text-align: center;
    flex-shrink: 0;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    border: none;
    border-radius: 5px;
    color: #10141c;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .submit-btn:hover {
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .new-order {
      padding: 15px; /* Reduce padding for smaller screens */
    }
  
    .new-order h2 {
      font-size: 1.8rem; /* Adjust heading size */
    }
  
    .form-group select,
    .slider-value {
      width: 100%; /* Ensure full width of input fields */
      padding: 8px; /* Reduce padding for smaller screens */
    }
  
    .slider-group .slider-container {
      flex-direction: column; /* Stack sliders and values vertically */
      align-items: stretch; /* Stretch to fit parent */
      gap: 5px; /* Reduce gap */
    }
  
    .slider-group .slider-value {
      width: 100%; /* Stretch slider value to full width */
      text-align: left; /* Align value to left */
    }
  
    .submit-btn {
      padding: 10px; /* Maintain button padding */
      font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .new-order {
      padding: 10px; /* Further reduce padding for very small screens */
    }
  
    .new-order h2 {
      font-size: 1.5rem; /* Smaller heading size */
    }
  
    .form-group select,
    .slider-value {
      padding: 6px; /* Compact padding for inputs */
      font-size: 0.9rem; /* Adjust font size */
    }
  
    .submit-btn {
      font-size: 0.8rem; /* Adjust button font size */
      padding: 8px; /* Smaller button padding */
    }
  }
  