.market-overview {
    padding: 20px;
    background: linear-gradient(135deg, #1b2735, #243b55);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: #d6d6d6;
    display: grid;
    gap: 15px;
  }
  
  .market-overview div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: linear-gradient(135deg, #10141c, #1a1f2d);
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .market-overview div:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(104, 210, 224, 0.6);
  }
  
  .market-overview label {
    font-weight: bold;
    color: #68d2e0;
    font-size: 1rem;
  }
  
  .market-overview span {
    font-weight: bold;
    color: #ff4d4d;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .market-overview {
      padding: 15px;
    }
  
    .market-overview label,
    .market-overview span {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .market-overview div {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  
    .market-overview label,
    .market-overview span {
      font-size: 0.8rem;
    }
  }
  