.dashboard {
    padding: 20px;
    color: #d6d6d6;
  }
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .dashboard-header h1 {
    font-size: 2.5rem;
    color: #68d2e0;
  }
  
  .dashboard-header p {
    font-size: 1.2rem;
    color: #a5abb7;
  }
  
  .dashboard-content {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
    height: 400px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .candlestick-chart-container {
    flex: 2.5;
    background: linear-gradient(135deg, #1b2735, #23344a);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .candlestick-chart-container > div {
    width: 100%;
    height: 600px;
  }
  
  .swap-card {
 
   
    background: linear-gradient(135deg, #1b2735, #243b55);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: #d6d6d6;
    height: 100%;
    width: auto;
   
  }
  
  .swap-section {
    margin-bottom: 20px;
  }
  
  .swap-section label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
  
  .swap-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .swap-input-group select,
  .swap-input-group input {
    flex: 1;
    padding: 10px;
    background: #1e2a3a;
    border: 1px solid #68d2e0;
    border-radius: 5px;
    color: #d6d6d6;
  }
  
  .swap-input-group select:focus,
  .swap-input-group input:focus {
    outline: none;
    border-color: #ff4d4d;
  }
  
  .quick-buttons {
    display: flex;
    gap: 5px;
  }
  
  .quick-buttons button {
    padding: 5px 10px;
    background: #243b55;
    border: 1px solid #68d2e0;
    border-radius: 5px;
    color: #d6d6d6;
    cursor: pointer;
  }
  
  .quick-buttons button:hover {
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    color: #10141c;
  }
  
  .swap-button {
    width: 100%;
    padding: 10px;
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    border: none;
    border-radius: 5px;
    color: #10141c;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .swap-button:hover {
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
  }
  
  @media (max-width: 768px) {
    .dashboard-content {
      flex-direction: column;
      height:auto;
      gap: 20px;
    
    }
  
    .candlestick-chart-container,
    .swap-card {
     
      width: 80%;
    }
  
    .candlestick-chart-container > div {
      height: 400px;
    }
  }

