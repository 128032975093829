.token-selector {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1000;
  }
  
  .token-selector.open {
    opacity: 1;
    visibility: visible;
  }
  
  .token-selector-content {
    background: linear-gradient(135deg, #1b2735, #243b55);
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: #d6d6d6;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #d6d6d6;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .token-list {
    margin-top: 20px;
  }
  
  .token-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #1e2a3a;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .token-item:hover {
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
  }
  
  .token-symbol {
    font-size: 1.2rem;
    font-weight: bold;
    color: #68d2e0;
  }
  
  .token-details {
    flex: 1;
    margin-left: 10px;
  }
  
  .token-name {
    display: block;
    color: #a5abb7;
  }
  
  .token-balance {
    display: block;
    font-size: 0.9rem;
  }
  
  .token-price {
    font-size: 0.9rem;
    color: #ff4d4d;
  }
  .manual-entry {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .manual-entry input {
    padding: 10px;
    background: #1e2a3a;
    border: 1px solid #68d2e0;
    border-radius: 5px;
    color: #d6d6d6;
  }
  
  .manual-entry input:focus {
    outline: none;
    border-color: #ff4d4d;
  }
  
  .manual-entry button {
    padding: 10px;
    background: linear-gradient(90deg, #68d2e0, #ff4d4d);
    border: none;
    border-radius: 5px;
    color: #10141c;
    font-weight: bold;
    cursor: pointer;
  }
  
  .manual-entry button:hover {
    background: linear-gradient(135deg, #ff4d4d, #68d2e0);
  }
  
  .error-message {
    color: #ff4d4d;
    font-size: 0.9rem;
  }
  